import React from "react"
import { Helmet } from "react-helmet"

export default () => (
    <div class="home">

        <Helmet>
            <html lang="en" />
            <meta name="description" content="Personal page of Shari Cruz." />
            <title>Shari Cruz</title>
            <link rel="canonical" href="https://sharicruz.net/" />
            <link href="https://necolas.github.io/normalize.css/latest/normalize.css" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Alice|Cabin|Josefin+Sans" rel="stylesheet" />
        </Helmet>

        <h1>Hello, I'm Shari.</h1>
        <p>I read manga, watch anime, and write words.</p>
        <div><a href="mailto:hello@sharicruz.net">hello@sharicruz.net</a></div>
        
    </div>
)
